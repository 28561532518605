<template>
  <div class="one-column-width">
    <div class="section-title ml-md-4" v-if="loading">
      <h1>LOADING Preview DATA...</h1>
    </div>

    <div class="section-title ml-md-4" v-if="!loading">
      <h1>Preview of {{ formName }}</h1>
    </div>
    <div class="col-md-12 pt-2 mt-5" v-for="(tab, index) in formData" :key="index">
      <h2 style="border-bottom: 1px solid">{{ tab.name }}</h2>
      <div class="col-md-12 row">
        <div
          class="col-md-3 pt-2 mt-5 form-group position-relative"
          v-for="(field, index) in tab.fields"
          :key="index"
        >
          <label>{{ field.label }} <span class="text-danger">*</span></label>
          <input
            v-model="field.value"
            :type="field.type"
            :placeholder="field.caption"
            :maxlength="field.length"
            v-if="field.tab_id == tab.id"
            class="form-control pl-5"
          />
        </div>
      </div>
    </div>
    <div class="mt-4 pt-2">
      <button
        type="button"
        class="btn btn-soft-danger ml-2"
        @click="cancel_admission_form"
        v-if="!loading"
      >
        Cancel Preview
      </button>
    </div>
    <br />
  </div>
</template>

<script>
import AdmissionForm from "@/MainServices/AdmissionForm.js";

export default {
  data() {
    return {
      formData: [],
      formName: null,
      loading: false,
    };
  },
  components: {},

  computed: {},

  methods: {
    cancel_admission_form() {
      this.$router.push("/institute/admission-forms");
    },
  },
  mounted() {
    this.loading = true;
    AdmissionForm.getSingleFormPreview(localStorage.getItem("preview_form_id")).then(
      (response) => {
        this.formName = response.data[0].name;
        response.data[0].institute_form_tabs.map((tabs) => {
          let store_local = {
            id: tabs.id,
            name: tabs.name,
            sequence: tabs.sequence,
            fields: [],
          };
          tabs.fields.map((field) => {
            store_local.fields.push({
              id: field.id,
              caption: field.caption,
              sequence: field.sequence,
              tab_id: field.tab_id,
              label: field.label,
              type: field.type,
              length: field.length,
              frontend: field.frontend,
              options: field.options,
              editable: field.editable,
              value: null,
            });
          });
          this.formData.push(store_local);
        });
        this.loading = false;
      }
    );
  },
};
</script>
