import ApiInstance from './ApiInstance.js'

export default {
  getFormTabs() {
    return ApiInstance({ requiresAuth: true }).get('/admission-form-tabs');
  },
  addNewTabs(Data) {
    return ApiInstance({ requiresAuth: true }).post('/admission-form-tabs', {tabs: Data});
  },
  editTab(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/admission-form-tabs/${id}`, Data[0]);
  },
  deleteTab(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/admission-form-tabs/${id}`);
  },
  getFormFields() {
    return ApiInstance({ requiresAuth: true }).get('/admission-form-tab-fields');
  },
  addNewFields(Data) {
    return ApiInstance({ requiresAuth: true }).post('/admission-form-tab-fields', {fields: Data});
  },
  editField(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/admission-form-tab-fields/${id}`, Data[0]);
  },
  deleteField(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/admission-form-tab-fields/${id}`);
  },
  getForm() {
    return ApiInstance({ requiresAuth: true }).get('/admission-form-templates');
  },
  addNewForm(Data) {
    return ApiInstance({ requiresAuth: true }).post('/admission-form-templates',  Data[0]);
  },
  editForm(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/admission-form-templates/${id}`, Data[0]);
  },
  deleteForm(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/admission-form-templates/${id}`);
  },
  submitApplicationForm(admissionFormData)
  {
    return ApiInstance({ requiresAuth: true }).post(`/admission-form-submit`,  {FormData: admissionFormData});
  },
  getStudentApplications()
  {
    return ApiInstance({ requiresAuth: true }).get(`/student-admission-applications`);
  },
  getInstituteStudentApplications()
  {
    return ApiInstance({ requiresAuth: true }).get(`/institute-admission-applications`);
  },
  updateInstituteStudentApplications(data)
  {
    return ApiInstance({ requiresAuth: true }).post(`/institute-admission-applications-status`, data);
  },
  getInstituesList()
  {
    return ApiInstance({ requiresAuth: true }).get(`/institute-list`);
  },
  getInstitesForms()
  {
    return ApiInstance({ requiresAuth: true }).get(`/institutes-forms-list`);
  },
  addNewTemplateToInstitute(data)
  {
    return ApiInstance({ requiresAuth: true }).post(`/institutes-forms-add`, data);
  },
  deleteInstituteTemplate(data)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/institutes-forms-delete/${data}`);
  },
  getInstituteTemplates()
  {
    return ApiInstance({ requiresAuth: true }).get(`/institute-forms-list`);
  },
  getSingleFormPreview(Formid)
  {
    return ApiInstance({ requiresAuth: true }).post(`/institute-form-privew`,{id : Formid});
  },
  updateInstituteFormFields(fieldsValues)
  {
    return ApiInstance({ requiresAuth: true }).post(`/institute-form-update-fields`,{fields : fieldsValues});
  }
  

}
