import axios from 'axios';
import  API  from "@/core/api";

export default ({ requiresAuth = false } = {}) => {
  const options = {};
  options.baseURL = API.API_URL;
  options.headers= {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  if (requiresAuth) {
    options.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }

  const instance = axios.create(options);
  
  return instance;
};